export enum HeatmapColumnsEnum {
  EMPLOYEES = 'employees',

  ABSENT = 'absent',
  NEW_HIRES = 'newHires',
  LEAVERS = 'leavers',
  MEETINGS = 'meetings',
  VIDEO_CALLS = 'videoCalls',
  SENT_EMAILS = 'sentEmails',
  MESSAGES = 'messages',

  MEETINGS_DIFFERENCE = 'meetingsDifference',
  VIDEO_CALLS_DIFFERENCE = 'videoCallsDifference',
  EMAILS_DIFFERENCE = 'emailsDifference',
  MESSAGES_DIFFERENCE = 'messagesDifference',
}
