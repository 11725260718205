export enum HeatmapColumnsEnum {
  MEDIAN_TENURE = 'medianTenure',
  BEYOND_MEDIAN_TENURE = 'beyondMedianTenure',
  PROMOTION_COUNT = 'promotionCount',
  SINCE_PROMOTION = 'sincePromotion',
  SINCE_PAY_INCREASE = 'sincePayIncrease',
  IN_RISK_GROUP = 'inRiskGroup',
  RISK_OF_ATTRITION = 'riskOfAttrition',
  QUITE_QUITTERS_COUNT = 'quiteQuittersCount',
  TOP_PERFORMERS_IN_RISK = 'topPerformersInRisk',

  ENGAGEMENT_DIFFERENCE = 'engagementDifference',
  PRODUCTIVITY_DIFFERENCE = 'productivityDifference',
  BURNOUT_DIFFERENCE = 'burnoutDifference',

  RETENTION_SAVING = 'retentionSaving',
}
